import React from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { debounce } from 'lodash';
import {
  getCorporateList,
  getSearchedCorporateList
} from '../actions/corporateList.action';
import Loader from './loader';
// import noResult from '../assets/images/no-result.png';
import { toast } from 'react-hot-toast';
import EmptySideScreenState from './EmptySideScreenState';

const NoDataView = (
  <>
    <EmptySideScreenState pageName={'corporate'} />
  </>
);

class ListCorporate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      corporateListMain: this.props.corporateList,
      list: []
    };
    this.searchInputRef = React.createRef();
  }

  searchCorp = value => {
    if (value !== '') {
      let newList = this.props.corporateList.filter(item => {
        const lc = item.name.toLowerCase();
        const filter = value.toLowerCase();
        return lc.includes(filter);
      });

      this.setState({ corporateListMain: newList });
    } else {
      this.setState({ corporateListMain: this.props.corporateList });
    }
  };

  componentDidMount() {
    this.props.getCorporateList();
    this.setState({ corporateListMain: this.props.corporateList });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.corporateList !== this.props.corporateList) {
      this.setState({ corporateListMain: this.props.corporateList });
    }
  }
  clearFilter = e => {
    e.preventDefault();
    this.searchInputRef.current.value = '';
    this.props.getCorporateList();
  };

  // componentDidUpdate(prevProps) {
  //   if (
  //     this.props.corporateList?.length > 0 &&
  //     this.searchInputRef.current &&
  //     this.searchInputRef.current.value !== '' &&
  //     prevProps.corporateList !== this.props.corporateList
  //   ) {
  //     setTimeout(() => {
  //       const filteredList = this.props.corporateList.filter(item => {
  //         const lc = item.name.toLowerCase();
  //         const filter = this.searchInputRef.current.value.toLowerCase();
  //         return lc.includes(filter);
  //       });
  //       const areEqual = filteredList.every(
  //         (item, index) => item === this.props.corporateList[index]
  //       );
  //       if (!areEqual) {
  //         this.searchCorp(this.searchInputRef.current.value);
  //       }
  //     }, 1000);
  //   }
  // }

  callSearchCorp = event => {
    // {console.log("valur => ", event.target.value)}

    toast.dismiss();
    if (event.target.value.length >= 0) {
      this.searchCorp(event.target.value);
    } else if (
      event.target.value === '' &&
      this.props.corporateList.length === 0
    ) {
      this.clearFilter(event);
    }
    // this.setState({ corporateListMain: this.props.corporateList });
    // else {
    //   this.clearFilter(event);
    //   // toast.error('Please enter at least 3 characters');
    // }
  };

  checkInput = event => {
    if (event.key === 'Enter') {
      this.callSearchCorp(event);
    }
  };

  render() {
    const { corporateList } = this.props;
    const debouncedHandleChangeX = debounce(e => {
      this.callSearchCorp(e);
    }, 300);
    if (!corporateList) {
      return <Loader loaderText="Loading..." />;
    }
    if (
      !corporateList.length &&
      this.searchInputRef.current &&
      !this.searchInputRef.current.value
    ) {
      return NoDataView;
    }

    return (
      <div className="row p-4">
        {/* <div className="col-12 mb-4">
          <div className="transparent input-group col-3 p-0">
            <div className="input-group-prepend">
              <i className="input-group-text fa fa-search form-control-feedback"></i>
            </div>
            <input
              className="form-control"
              type="text"
              ref={this.searchInputRef}
              onChange={e => {
                e.persist();
                debouncedHandleChange(e);
              }}
              onKeyDown={this.checkInput}
              placeholder="Search by corporate name"
            />
            {this.searchInputRef.current && this.searchInputRef.current.value && (
              <div className="input-group-append">
                <a
                  href="/"
                  onClick={this.clearFilter}
                  title="Clear search"
                  className="input-group-text">
                  <i className="fas fa-times"></i>
                </a>
              </div>
            )}
          </div>
        </div> */}
        <div className="input-group ml-5 row">
          <div className="col-8"></div>

          <div className="col-4">
            <span className="form-group col-10 mt-4 float-right">
              <button
                type="button"
                className="btn position-absolute"
                // onClick={this.serachCorp}
                style={{ right: '60px', top: '4px', zIndex: '99' }}>
                <i className="fa fa-search form-control-feedback"></i>
              </button>
              <input
                type="text"
                autoComplete="off"
                name="search"
                style={{ right: '4%' }}
                placeholder="Search Corporate"
                // value={(e)=>this.searchCorp(e.target.value)}
                // onChange={e => this.handleChange(e.target.value)}
                ref={this.searchInputRef}
                onChange={e => {
                  e.persist();
                  debouncedHandleChangeX(e);
                }}
                onKeyDown={this.checkInput}
                className="col-11 standard-search-style"
              />
            </span>
          </div>
        </div>
        <div className="row align-items-stretch justify-content-center">
          {this.state?.corporateListMain?.length
            ? this.state.corporateListMain.map((item, index) => {
                {
                  /* {this.props?.corporateList?.length ?  this.props.corporateList.map((item, index) => { */
                }
                {
                  /* {this.state?.corporateListMain?.length ?  this.state.corporateListMain.map((item, index) => {  */
                }
                return (
                  <article
                    className="col-3 row perspective-anim p-0 m-0"
                    key={item.logo_url + index}>
                    <div className="card col-12 p-2 mb-2 scale-092 rounded">
                      <div className="card-body">
                        <img
                          className="img-thumbnail logo mb-4"
                          src={item.logo_url}
                          height="100"
                          alt={`${item.name}-logo`}
                        />
                        <h2 className="card-title">{item.name}</h2>
                        <h5 className="card-subtitle mb-2 text-muted">
                          {item.corporate_type}
                        </h5>
                        <hr />
                        <dl className="mt-4">
                          <dt className="h6 mt-2 font-weight-bold text-666565 mb-3">
                            SDG
                          </dt>
                          <span className="lead font-weight-normal pill h6">
                            {item.thematicAreaInFocus}
                          </span>
                          <dt className="h6 font-weight-bold text-666565 mt-4">
                            State(s) of Operations
                          </dt>
                          <dd className="lead font-weight-normal locations">
                            {item.city}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </article>
                );
              })
            : NoDataView}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  corporateList: state.corporate_list.corporateList
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getCorporateList, getSearchedCorporateList },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCorporate);
