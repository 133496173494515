import Keycloak from 'keycloak-js';
import { toast } from 'react-hot-toast';
import { SERVER_NAME } from '../constants/path.constants';
let keycloak;
let initPromise;
export function initializeKeycloak() {
  return new Promise((resolve, reject) => {
    keycloak = keycloak
      ? keycloak
      : new Keycloak({
          url: process.env.REACT_APP_KEYCLOAK_URL,
          clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
          realm: process.env.REACT_APP_KEYCLOAK_REALM,
          useNativePromise: true
        });
    let tempTokenHolder = null;
    const initOptions = {
      onLoad: 'login-required',
      cors: 'native',
      promiseType: 'native',
      silentCheckSsoRedirectUri: false,
      checkLoginIframe: false,
      responseMode: 'query'
    };
    if ((tempTokenHolder = localStorage.getItem('id_token'))) {
      initOptions.idToken = tempTokenHolder;
    }
    if ((tempTokenHolder = localStorage.getItem('access_token'))) {
      initOptions.token = tempTokenHolder;
    }
    if ((tempTokenHolder = localStorage.getItem('refresh_token'))) {
      initOptions.refreshToken = tempTokenHolder;
    }
    initPromise = initPromise ? initPromise : keycloak.init(initOptions);

    initPromise.then(authenticated => {
      const userDetails = keycloak.tokenParsed;
      if (
        (userDetails.user_type === 1 || userDetails.user_type === 2) &&
        authenticated
      ) {
        localStorage.setItem('access_token', keycloak.token);
        localStorage.setItem('refresh_token', keycloak.refreshToken);
        localStorage.setItem('id_token', keycloak.idToken);
        resolve({
          userDetails: keycloak.tokenParsed
        });
        let dummyImg = document.createElement('img');
        dummyImg.setAttribute(
          'src',
          `${SERVER_NAME.S3}/download?d=${keycloak.idToken}`
        );
        document.body.appendChild(dummyImg);
        setTimeout(() => {
          document.body.removeChild(dummyImg);
          dummyImg = null;
        }, 1000);
      } else {
        toast.error('You are not authorized to see this page', {
          autoClose: 5000
        });
        reject('authentication failure');
        setTimeout(() => {
          logout();
        }, 5000);
      }
    });
  });
}

export function updateToken(minvalidity) {
  if (keycloak) return keycloak.updateToken(minvalidity);
}

export function token() {
  if (keycloak) return keycloak.token;
}

export function isTokenExpired(minvalidity) {
  if (keycloak) return keycloak.isTokenExpired(minvalidity);
}

export function login() {
  return keycloak.login();
}

export function userDetails() {
  if (keycloak && keycloak.tokenParsed) return keycloak.tokenParsed;
  return null;
}

export function logout(e) {
  if (e) {
    e.preventDefault();
  }
  window.stop();
  localStorage.clear();
  sessionStorage.clear();
  keycloak.logout();
}
